import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { DynamicForm } from '@app/models/Form';
import { AlertService } from '@app/services/alert.service';
import { UserAPIService } from '@app/services/user.api.service';
import { SharedService } from '@app/store/shared/shared.service';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-forgotten-password-step1',
  templateUrl: './forgotten-password-step1.component.html',
  styleUrls: ['./forgotten-password-step1.component.scss'],
})
export class ForgottenPasswordStep1Component implements OnInit {
  public forgottenPasswordStep1Form: DynamicForm;

  constructor(
    private modalController: ModalController,
    private translateService: TranslateService,
    private userApiService: UserAPIService,
    private sharedService: SharedService,
    private alertService: AlertService,
    private alertController: AlertController
  ) {}

  ngOnInit() {
    this.forgottenPasswordStep1Form = {
      fields: [
        {
          type: 'email',
          name: 'email',
          defaultValue: '',
          label: this.translateService.instant(
            'FORGOTTEN_PASSWORD.STEP1.FORM.EMAIL.LABEL'
          ),
          errorMessage: this.translateService.instant(
            'FORGOTTEN_PASSWORD.STEP1.FORM.EMAIL.ERRORS.REQUIRED'
          ),
          validators: [Validators.required, Validators.email],
          input: {
            type: 'email',
          },
        },
      ],
      buttons: [
        {
          type: 'submit',
          label: this.translateService.instant(
            'FORGOTTEN_PASSWORD.STEP1.FORM.SUBMIT'
          ),
        },
      ],
    };
  }

  async onSubmit(formData) {
    if (!formData.email) {
      return;
    }

    this.sharedService.showLoading({
      text: this.translateService.instant(
        'FORGOTTEN_PASSWORD.STEP1.CODE_SENDING'
      ),
    });

    this.userApiService
      .sendResetPassword(formData)
      .pipe(
        finalize(() => {
          this.sharedService.hideLoading();
        }),
        catchError((error) => {
          console.log(error);
          this.alertService.show({
            title: this.translateService.instant(
              'FORGOTTEN_PASSWORD.STEP1.ERRORS.SENDING.TITLE'
            ),
            message: this.translateService.instant(
              'FORGOTTEN_PASSWORD.STEP1.ERRORS.SENDING.MESSAGE'
            ),
          });
          return throwError(error);
        })
      )
      .subscribe(async (data) => {
        const alert = await this.alertController.create({
          header: this.translateService.instant(
            'FORGOTTEN_PASSWORD.STEP1.SUCCESS.TITLE'
          ),
          message: this.translateService.instant(
            'FORGOTTEN_PASSWORD.STEP1.SUCCESS.MESSAGE'
          ),
          buttons: [
            {
              text: this.translateService.instant('ACTIONS.CANCEL'),
              role: 'cancel',
              handler: () => {
                this.dismissModal();
              },
            },
            {
              text: this.translateService.instant('ACTIONS.NEXT'),
              handler: () => {
                this.dismissModal(formData);
              },
            },
          ],
        });

        await alert.present();
      });
  }

  dismissModal(data = null) {
    this.modalController.dismiss(data);
  }
}
