import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SlideDownAnimation } from '@app/components/form/slidedown.animation';
import { DynamicField } from '@app/models/Form';

@Component({
  selector: 'app-form-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
  animations: [SlideDownAnimation],
})
export class FormGroupComponent implements OnInit {
  @Input() group: FormGroup;
  @Input() errorMessage: string = null;
  @Input() fields: DynamicField[];

  constructor() {}

  ngOnInit() {}

  isString(data) {
    return typeof data === 'string';
  }

  get isAllTouched() {
    return Object.values(this.group.controls).every((c) => c.touched);
  }
}
