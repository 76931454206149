import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DynamicField, DynamicForm } from '@app/models/Form';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent {
  private complete$: Subject<boolean>;
  private _inputForm: DynamicForm;

  @Input('form')
  set inputForm(value) {
    this._inputForm = value;
    this.createForm();
  }

  get inputForm() {
    return this._inputForm;
  }

  @Input() formTag = true;

  @Output() submit = new EventEmitter();
  @Output() submitAttempt = new EventEmitter();
  @Output() valueChange = new EventEmitter();

  buildedForm: FormGroup;

  constructor() {}

  createForm() {
    if (!this.inputForm) {
      return;
    }

    if (this.complete$) {
      this.complete$.next(true);
      this.complete$.unsubscribe();
    }

    this.complete$ = new Subject<boolean>();

    this.buildedForm = new FormGroup(
      this.recursiveParse(this.inputForm.fields),
      this.inputForm.validators
    );

    this.valueChange.emit(this.buildedForm.value);

    this.buildedForm.valueChanges
      .pipe(takeUntil(this.complete$))
      .subscribe((data) => {
        this.valueChange.emit(data);
      });
  }

  isString(data) {
    return typeof data === 'string';
  }

  private recursiveParse(fields) {
    return fields.reduce((acc: {}, val: DynamicField) => {
      if (val.type === 'group') {
        acc[val.name] = new FormGroup(
          this.recursiveParse(val.fields),
          val.validators
        );
      } else if (val.type !== 'content') {
        acc[val.name] = new FormControl(
          { value: val.defaultValue, disabled: !!val.disabled },
          val.validators
        );
      }

      return acc;
    }, {});
  }

  onSubmit(ev) {
    ev.stopPropagation();
    this.buildedForm.markAllAsTouched();

    if (!this.buildedForm.valid) {
      this.submitAttempt.emit();
      return;
    }

    this.submit.emit(this.buildedForm.value);
  }

  resetForm() {
    this.buildedForm.reset();
  }
}
