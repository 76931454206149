import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthState } from '@app/store/auth/auth.state';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-login-expired',
  templateUrl: './login-expired.component.html',
  styleUrls: ['./login-expired.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginExpiredComponent implements OnInit {
  ngOnInit(): void {}

  constructor(private modalController: ModalController, private store: Store) {}

  dismissModal() {
    this.modalController.dismiss({});
  }

  onLogin() {
    const token = this.store.selectSnapshot(AuthState.token);

    this.modalController.dismiss({
      token,
    });
  }
}
