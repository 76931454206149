import { Injectable } from '@angular/core';
import { LoadingService } from '@app/services/loading.service';
import { TranslateService } from '@ngx-translate/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { SharedActions } from './shared.actions';
import { SharedStateModel } from './shared.model';

export const defaultSharedState: SharedStateModel = {
  loading: false,
  alreadyOpen: false,
  alreadySeenCartInfo: false,
  cgv: '',
  thirdpartylicenses: '',
  countries: {},
  creditPacks: [],
  upload: {
    uploading: false,
    progress: 0,
  },
};

@State<SharedStateModel>({
  name: 'shared',
  defaults: {
    ...defaultSharedState,
  },
})
@Injectable()
export class SharedState implements NgxsOnInit {
  @Selector()
  static loading(state: SharedStateModel) {
    return state.loading;
  }

  @Selector()
  static upload(state: SharedStateModel) {
    return state.upload;
  }

  @Selector()
  static alreadyOpen(state: SharedStateModel) {
    return state.alreadyOpen;
  }

  @Selector()
  static alreadySeenCartInfo(state: SharedStateModel) {
    return state.alreadySeenCartInfo;
  }

  @Selector()
  static countries(state: SharedStateModel) {
    const countries = [];

    for (const [key, value] of Object.entries(state.countries)) {
      countries.push({
        alpha3: key,
        name: value,
      });
    }

    return countries;
  }

  @Selector()
  static creditPacks(state: SharedStateModel) {
    return state.creditPacks.map((element) => {
      return {
        ...element,
        unitPrice:
          parseFloat(element.discount ? element.discount : element.amount) /
          element.quantity,
      };
    });
  }

  @Selector()
  static cgv(state: SharedStateModel) {
    return state.cgv;
  }

  @Selector()
  static thirdpartylicenses(state: SharedStateModel) {
    return state.thirdpartylicenses;
  }

  @Action(SharedActions.ShowLoading)
  showLoadingByState(
    context: StateContext<SharedStateModel>,
    payload: SharedActions.ShowLoading
  ) {
    this.loadingService.show(payload.options);

    const state = context.getState();
    context.setState({
      ...state,
      loading: true,
    });
  }

  @Action(SharedActions.SetUploading)
  showUploadingByState(
    context: StateContext<SharedStateModel>,
    payload: SharedActions.SetUploading
  ) {
    if (payload.uploading) {
      this.loadingService.show({
        text: this.translateService.instant('FRONT_CARD.UPDATING'),
      });
    } else {
      this.loadingService.hide();
    }

    const state = context.getState();
    context.setState({
      ...state,
      upload: {
        ...state.upload,
        uploading: payload.uploading,
      },
    });
  }

  @Action(SharedActions.SetUploadingProgress)
  setUploadinProgressgByState(
    context: StateContext<SharedStateModel>,
    payload: SharedActions.SetUploadingProgress
  ) {
    this.loadingService.updateMessage(`Upload en cours : ${payload.progress}%`);

    const state = context.getState();
    context.setState({
      ...state,
      upload: {
        ...state.upload,
        progress: payload.progress,
      },
    });
  }

  @Action(SharedActions.HideLoading)
  hideLoadingByState(context: StateContext<SharedStateModel>) {
    this.loadingService.hide();

    const state = context.getState();
    context.setState({
      ...state,
      loading: false,
    });
  }

  @Action(SharedActions.CGV)
  cgvByState(
    context: StateContext<SharedStateModel>,
    payload: SharedActions.CGV
  ) {
    const state = context.getState();
    context.setState({
      ...state,
      cgv: payload.content,
    });
  }

  @Action(SharedActions.ThirdPartyLicenses)
  thirdpartylicensesByState(
    context: StateContext<SharedStateModel>,
    payload: SharedActions.ThirdPartyLicenses
  ) {
    const state = context.getState();
    context.setState({
      ...state,
      thirdpartylicenses: payload.content,
    });
  }

  @Action(SharedActions.FetchCountries)
  fetchCountriesByState(
    context: StateContext<SharedStateModel>,
    payload: SharedActions.FetchCountries
  ) {
    const state = context.getState();
    context.setState({
      ...state,
      countries: payload.countries,
    });
  }

  @Action(SharedActions.FetchCreditPacks)
  fetchCreditPacksByState(
    context: StateContext<SharedStateModel>,
    payload: SharedActions.FetchCreditPacks
  ) {
    const state = context.getState();
    context.setState({
      ...state,
      creditPacks: payload.creditPacks,
    });
  }

  @Action(SharedActions.CloseStarterGuide)
  closeStarterGuideByState(context: StateContext<SharedStateModel>) {
    const state = context.getState();
    context.setState({
      ...state,
      alreadyOpen: true,
    });
  }

  @Action(SharedActions.CloseInfoCart)
  closeInfoCartByState(context: StateContext<SharedStateModel>) {
    const state = context.getState();
    context.setState({
      ...state,
      alreadySeenCartInfo: true,
    });
  }

  constructor(
    private loadingService: LoadingService,
    private translateService: TranslateService
  ) {}

  ngxsOnInit(ctx: StateContext<SharedStateModel>) {
    const state = ctx.getState();
    ctx.patchState({
      loading: false,
      alreadySeenCartInfo: !!state.alreadySeenCartInfo,
      upload: {
        uploading: false,
        progress: 0,
      },
    });
  }
}
