import { InjectionToken } from '@angular/core';

export const APP_CONST = new InjectionToken<SkConstants>('app.const');

export interface SkConstants {
  postcardsFormat: string[];
  popularPostcardsFormat: string[];
}

export const AppConstants: SkConstants = {
  postcardsFormat: [
    '1-h',
    '1,1-v',
    '1,1-h',
    '1,2-v',
    '1,2-h',
    '2,1-v',
    '2,1-h',
    '3-h',
    '2,2-h',
    '1,3-v',
    '1,3-h',
    '3,1-v',
    '3,1-h',
    '1,1,1,1-v',
    '2,3-v',
    '2,3-h',
    '3,2-v',
    '3,2-h',
    '1,4-v',
    '1,4-h',
    '4,1-v',
    '4,1-h',
    '3,3-v',
    '3,3-h',
    '2,4-v',
    '2,4-h',
    '4,2-v',
    '4,2-h',
    '3,4-v',
    '3,4-h',
    '4,3-v',
    '4,3-h',
    '4,4-v',
    '4,4-h',
  ],
  popularPostcardsFormat: ['1-h', '1,1-v', '1,2-v', '2,2-h'],
};
