import { CreatePostcard } from '@app/models/Postcard';
import { Recipient } from '@app/models/Recipient';

export namespace PostcardActions {
  export class Create {
    static readonly type = '[Postcard] Create';
    constructor(public payload: any) {}
  }

  export class Edit {
    static readonly type = '[Postcard] Edit';
    constructor(public payload: any) {}
  }

  export class Delete {
    static readonly type = '[Postcard] Delete';
    constructor(public id: number) {}
  }

  export class FetchAll {
    static readonly type = '[Postcard] Fetch all';
    constructor(public payload: any) {}
  }

  export class FetchOne {
    static readonly type = '[Postcard] Fetch one';
    constructor(public id: number) {}
  }

  export class InitCreatePostcard {
    static readonly type =
      '[Create postcard] Add format and nbPhotos to create postcard';
    constructor(public format: string, public nbPhotos: number) {}
  }

  export class AddPhotosToCreate {
    static readonly type = '[Create postcard] Add Photos to Create Postcard';
    constructor(public photos: string[]) {}
  }

  export class AddContentToCreate {
    static readonly type = '[Create postcard] Add content to Create Postcard';
    constructor(public content: string) {}
  }

  export class AddNameToCreate {
    static readonly type = '[Create postcard] Add name to Create Postcard';
    constructor(public postcardName: string) {}
  }

  export class AddRecipientsToCreate {
    static readonly type =
      '[Create postcard] Add recipients to Create Postcard';
    constructor(public recipients: Recipient[]) {}
  }

  export class AddPostcardidToCreate {
    static readonly type =
      '[Create postcard] Add postcard id to Create Postcard';
    constructor(public postcardId: number) {}
  }

  export class SetCreatePostcard {
    static readonly type = '[Create postcard] Set full item to Create Postcard';
    constructor(public createPostcard: CreatePostcard) {}
  }

  export class ClearCreatePostcard {
    static readonly type = '[Create postcard] Clear create Postcard';
  }
}
