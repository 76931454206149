import { Injectable } from '@angular/core';
import { AskForLegalInformationsComponent } from '@app/components/ask-for-legal-informations/ask-for-legal-informations.component';
import { AuthState } from '@app/store/auth/auth.state';
import { SharedService } from '@app/store/shared/shared.service';
import { ResponseSignInWithApplePlugin } from '@capacitor-community/apple-sign-in';
import { Plugins } from '@capacitor/core';
import '@codetrix-studio/capacitor-google-auth';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { AlertService } from './alert.service';
import { ApiService } from './api.service';
import { FirebaseAnalyticsService } from './firebase-analytics.service';

const { FacebookLogin } = Plugins;

const FACEBOOK_PERMISSIONS = ['email'];

@Injectable({
  providedIn: 'root',
})
export class SsoService {
  constructor(
    private apiService: ApiService,
    private alertController: AlertController,
    private modalController: ModalController,
    private translateService: TranslateService,
    private sharedService: SharedService,
    private alertService: AlertService,
    private fba: FirebaseAnalyticsService,
    private store: Store
  ) {}

  async facebookLogin() {
    try {
      this.sharedService.showLoading({
        text: this.translateService.instant('SSO.FACEBOOK_SIGNIN'),
        delay: 60000,
      });

      const isLoggedIn = await this.facebookCurrentState();

      if (!isLoggedIn) {
        await this.facebookSignIn();
      }

      const { token, userId } = await this.facebookCurrentState();
      const userInfos = await this.getFacebookUserInfo(userId, token);

      let userEmail: string;

      this.sharedService.hideLoading();

      if (!userInfos.email) {
        userEmail = await this.askForEmail();
      } else {
        userEmail = userInfos.email;
      }

      this.sharedService.showLoading({
        text: this.translateService.instant('SSO.CHECK'),
      });

      const isAlreadyRegister = await this.isAlreadyRegisterWith(userId);

      this.sharedService.hideLoading();

      const additionalData = isAlreadyRegister
        ? {}
        : await this.askForLegalInformations();

      if (!additionalData) {
        throw new Error('Connection aborted');
      }

      this.fba.logEvent(isAlreadyRegister ? 'login' : 'sign_up', {
        method: 'facebook',
      });

      return {
        ...additionalData,
        accessToken: token,
        email: userEmail,
        userId,
        locale: this.translateService.currentLang,
      };
    } catch (error) {
      console.log(error);
      this.sharedService.hideLoading();
      return null;
    }
  }

  async facebookCurrentState(): Promise<any> {
    try {
      const result = await Plugins.FacebookLogin.getCurrentAccessToken();
      return result && result.accessToken;
    } catch (e) {
      return false;
    }
  }

  async facebookSignIn(permissions = FACEBOOK_PERMISSIONS): Promise<any> {
    const result = await Plugins.FacebookLogin.login({ permissions });
    return result;
  }

  async facebookSignOut(): Promise<void> {
    const loggedIn = await this.facebookCurrentState();

    if (loggedIn) {
      await Plugins.FacebookLogin.logout();
    }
  }

  async getFacebookUserInfo(
    userId,
    userToken,
    fields = ['email']
  ): Promise<any> {
    const response = await fetch(
      `https://graph.facebook.com/${userId}?fields=${fields.join(
        ','
      )}&access_token=${userToken}`
    );
    const myJson = await response.json();
    return myJson;
  }

  async isAlreadyRegisterWith(userId, provider = 'facebook'): Promise<boolean> {
    const data = await this.apiService
      .get(`user/sso/${provider}/check/${userId}`)
      .toPromise();
    return data.result;
  }

  async askForEmail(): Promise<string> {
    const user = this.store.selectSnapshot(AuthState.user);

    if (user) {
      return user.email;
    }

    const modal = await this.alertController.create({
      header: this.translateService.instant('SSO.FACEBOOK_SIGNIN'),
      message: this.translateService.instant('SSO.NO_EMAIL'),
      inputs: [
        {
          name: 'email',
          type: 'email',
          placeholder: '',
        },
      ],
      buttons: [
        {
          text: this.translateService.instant('ACTIONS.CANCEL'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {},
        },
        {
          text: this.translateService.instant('ACTIONS.OK'),
          handler: () => {},
        },
      ],
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();

    if (!data) {
      return this.cancelSignIn();
    }

    return data.values && data.values.email ? data.values.email : '';
  }

  async askForLegalInformations(): Promise<any> {
    const modal = await this.modalController.create({
      component: AskForLegalInformationsComponent,
    });

    await modal.present();

    const { data } = await modal.onWillDismiss();

    if (!data) {
      return this.cancelSignIn();
    }

    return {
      hasSubscribedMarketing: data.hasSubscribedMarketing,
      referralCode: data.referralCode,
    };
  }

  cancelSignIn() {
    this.sharedService.hideLoading();
    this.alertService.show({
      title: this.translateService.instant('SSO.ERRORS.CANCEL.TITLE'),
      message: this.translateService.instant('SSO.ERRORS.CANCEL.MESSAGE'),
    });

    return null;
  }

  async googleLogin() {
    try {
      this.sharedService.showLoading({
        text: this.translateService.instant('SSO.GOOGLE_SIGNIN'),
        delay: 60000,
      });

      const data = await this.googleSignIn();

      this.sharedService.showLoading({
        text: this.translateService.instant('SSO.CHECK'),
      });

      const isAlreadyRegister = await this.isAlreadyRegisterWith(
        data.id,
        'google'
      );

      this.sharedService.hideLoading();

      const additionalData = isAlreadyRegister
        ? {}
        : await this.askForLegalInformations();

      if (!additionalData) {
        throw new Error('Connection aborted');
      }

      this.fba.logEvent(isAlreadyRegister ? 'login' : 'sign_up', {
        method: 'google',
      });

      return {
        ...additionalData,
        accessToken: data.authentication.idToken,
        email: data.email,
        userId: data.id,
        locale: this.translateService.currentLang,
      };
    } catch (error) {
      console.log(error);
      this.sharedService.hideLoading();
      return null;
    }
  }

  async appleLogin() {
    try {
      this.sharedService.showLoading({
        text: this.translateService.instant('SSO.APPLE_SIGNIN'),
        delay: 60000,
      });

      const { response } = await this.appleSignIn();

      this.sharedService.showLoading({
        text: this.translateService.instant('SSO.CHECK'),
      });

      const isAlreadyRegister = await this.isAlreadyRegisterWith(
        response.user,
        'apple'
      );

      this.sharedService.hideLoading();

      const additionalData = isAlreadyRegister
        ? {}
        : await this.askForLegalInformations();

      if (!additionalData) {
        throw new Error('Connection aborted');
      }

      this.fba.logEvent(isAlreadyRegister ? 'login' : 'sign_up', {
        method: 'apple',
      });

      return {
        ...additionalData,
        accessToken: response.identityToken,
        email: response.email,
        userId: response.user,
        locale: this.translateService.currentLang,
      };
    } catch (error) {
      console.log(error);
      this.sharedService.hideLoading();
      return null;
    }
  }

  private async appleSignIn(): Promise<ResponseSignInWithApplePlugin> {
    const { SignInWithApple } = Plugins;

    const data = await SignInWithApple.Authorize();
    return data;
  }

  private async googleSignIn(): Promise<any> {
    const { GoogleAuth } = Plugins;

    const data = await GoogleAuth.signIn();
    return data;
  }
}
