import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const SlideDownAnimation = trigger('slideDown', [
  state('in', style({ transform: 'translateY(0)', opacity: 1 })),
  transition('void => *', [
    style({ transform: 'translateY(-100%)', opacity: 0 }),
    animate('200ms 100ms ease-in-out'),
  ]),
]);
