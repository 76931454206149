import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { DynamicForm } from '@app/models/Form';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ask-for-legal-informations',
  templateUrl: './ask-for-legal-informations.component.html',
  styleUrls: ['./ask-for-legal-informations.component.scss'],
})
export class AskForLegalInformationsComponent implements AfterViewInit {
  public askLegalInformationForm: DynamicForm;

  @ViewChild('cgvLabel') cgvLabelElement: TemplateRef<any>;

  constructor(
    private modalController: ModalController,
    private cd: ChangeDetectorRef,
    private translateService: TranslateService
  ) {}

  ngAfterViewInit(): void {
    this.populateForm();
    this.cd.detectChanges();
  }

  onSubmit(data) {
    this.dismissModal({
      hasSubscribedMarketing: data.marketing,
      referralCode: data.referral,
    });
  }

  private populateForm() {
    this.askLegalInformationForm = {
      fields: [
        {
          type: 'text',
          name: 'referral',
          defaultValue: '',
          label: this.translateService.instant(
            'SIGNUP.FORM.REFERRALCODE.LABEL'
          ),
          validators: [],
          input: {
            type: 'text',
            placeholder: '',
          },
        },
        {
          type: 'checkbox',
          name: 'cgv',
          defaultValue: 0,
          label: this.cgvLabelElement,
          errorMessage: this.translateService.instant('SIGNUP.FORM.CGV.ERROR'),
          validators: [Validators.requiredTrue],
        },
        {
          type: 'checkbox',
          name: 'marketing',
          defaultValue: 0,
          label: this.translateService.instant('SIGNUP.FORM.MARKETING.LABEL'),
          validators: [],
        },
      ],
      buttons: [
        {
          type: 'submit',
          label: this.translateService.instant('SIGNUP.FORM.SUBMIT'),
        },
      ],
    };
  }

  dismissModal(data = null) {
    this.modalController.dismiss(data);
  }
}
