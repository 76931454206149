import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SkypaperFormModule } from '@app/components/form/form.module';
import { ModalModule } from '@app/components/modal/modal.module';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ForgottenPasswordStep2Component } from './forgotten-password-step2.component';

@NgModule({
  declarations: [ForgottenPasswordStep2Component],
  exports: [ForgottenPasswordStep2Component],
  imports: [
    CommonModule,
    IonicModule,
    ModalModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    SkypaperFormModule,
  ],
})
export class ForgottenPasswordStep2Module {}
