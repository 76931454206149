export namespace OrderActions {
  export class Create {
    static readonly type = '[Order] Create';
    constructor(public payload: any) {}
  }

  export class Edit {
    static readonly type = '[Order] Edit';
    constructor(public payload: any) {}
  }

  export class Delete {
    static readonly type = '[Order] Delete';
    constructor(public id: number) {}
  }

  export class AddItemTo {
    static readonly type = '[Order] Add item to';
    constructor(public payload: any) {}
  }

  export class FetchAll {
    static readonly type = '[Order] Fetch all';
    constructor(public payload: any) {}
  }

  export class FetchOne {
    static readonly type = '[Order] Fetch one';
    constructor(public id: number) {}
  }
}
