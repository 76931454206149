import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class UserAPIService {
  constructor(private apiService: ApiService) {}

  sendResetPassword(data): Observable<any> {
    return this.apiService.post('user/password/reset', data);
  }

  checkResetPasswordCode(data) {
    return this.apiService.post('user/password/check', data);
  }

  getUser() {
    return this.apiService.get('user/me');
  }

  updateUser(data) {
    return this.apiService.put('user/me', data);
  }
}
