import { version } from '../../package.json';
import { Environment } from './environment.model';

export const environment: Environment = {
  mode: 'Development',
  production: false,
  apiUrl: 'https://api.staging.skypaper.io/',
  stripeProd: 'pk_test_fcm5pdE3KBKCtBoryzDDVQV2',
  paypalProd:
    'AXQM4UUnlQO62vE-9qkR-zYLroNhXrgfWD82DK7O1ZBvGGgKa2vz0cV263hAvtVjgqQi8neINiOHx6KK',
  stripeTest: 'pk_test_fcm5pdE3KBKCtBoryzDDVQV2',
  paypalTest:
    'AXQM4UUnlQO62vE-9qkR-zYLroNhXrgfWD82DK7O1ZBvGGgKa2vz0cV263hAvtVjgqQi8neINiOHx6KK',
  sentry:
    'https://f5709d833a0d480b8ff5782d49b96f49@o114566.ingest.sentry.io/5517249',
  statusBarColor: '#ffffff',
  navigationBarColor: '#ffffff',
  version,
};
