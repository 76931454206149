import { Injectable } from '@angular/core';
import { LoadingOptions } from '@app/models/LoadingOptions';
import {
  AlertController,
  LoadingController,
  ToastController,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  get isLoadingDisplayed() {
    return this.loader !== null;
  }

  private loader: HTMLIonToastElement | HTMLIonLoadingElement;
  private delayBeforeAutoClosing = 30000;
  private timeout: ReturnType<typeof setTimeout>;

  constructor(
    public loadingController: LoadingController,
    public toastController: ToastController,
    public alertController: AlertController,
    private translateService: TranslateService
  ) {}

  private getHTMLLoader(message) {
    return `
      <div class="ion-text-center">
        <div class="skypaper-spinner">
          <img src="assets/img/icon.svg" alt="">
        </div>
        <p class="ion-text-center">${message}</p>
      </div>`;
  }

  private async getLoader(
    isMini: boolean,
    message: string
  ): Promise<HTMLIonLoadingElement | HTMLIonToastElement> {
    if (!isMini) {
      return this.loadingController.create({
        spinner: null,
        mode: 'ios',
        message: this.getHTMLLoader(message),
        translucent: true,
      });
    }

    return this.toastController.create({
      message,
    });
  }

  async show(options: LoadingOptions) {
    const { text, mini, delay } = {
      text: this.translateService.instant('GLOBAL.LOADING.MESSAGE'),
      mini: false,
      delay: this.delayBeforeAutoClosing,
      ...options,
    };

    this.hide();

    const message = text;

    this.loader = await this.getLoader(mini, message);

    await this.loader.present();

    this.timeout = setTimeout(async () => {
      const header = this.translateService.instant(
        'GLOBAL.LOADING.ERROR.TITLE'
      );
      const alertMessage = this.translateService.instant(
        'GLOBAL.LOADING.ERROR.MESSAGE'
      );

      const alert = await this.alertController.create({
        translucent: true,
        header,
        message: alertMessage,
        buttons: [this.translateService.instant('ACTIONS.OK')],
      });

      if (this.loader !== null) {
        this.hide();
        await alert.present();
      }
    }, delay);
  }

  updateMessage(message) {
    if (!this.loader) {
      return;
    }

    this.loader.message = this.getHTMLLoader(message);
  }

  hide() {
    clearTimeout(this.timeout);

    if (this.loader) {
      this.loader.dismiss();
    }

    this.loader = null;
  }
}
