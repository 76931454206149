import { Injectable } from '@angular/core';
import { CreditCard } from '@app/models/CreditCard';
import { TranslateService } from '@ngx-translate/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CreditcardActions } from './creditcard.actions';
import { CreditcardStateModel } from './creditcard.model';

export const defaultCreditcardState: CreditcardStateModel = {
  creditcards: [],
};

@State<CreditcardStateModel>({
  name: 'creditcards',
  defaults: {
    ...defaultCreditcardState,
  },
})
@Injectable()
export class CreditcardState {
  @Selector()
  static creditcards(state: CreditcardStateModel): CreditCard[] {
    return state.creditcards;
  }

  @Action(CreditcardActions.Create)
  createCreditcardByState(
    context: StateContext<CreditcardStateModel>,
    payload: CreditcardActions.Create
  ) {}

  @Action(CreditcardActions.FetchAll)
  fetchAllByState(
    context: StateContext<CreditcardStateModel>,
    data: CreditcardActions.FetchAll
  ) {
    const state = context.getState();
    const creditcards: CreditCard[] = data.payload;

    context.setState({
      ...state,
      creditcards,
    });
  }

  @Action(CreditcardActions.Delete)
  deleteCreditcardByState(
    context: StateContext<CreditcardStateModel>,
    payload: CreditcardActions.Delete
  ) {
    const state = context.getState();
    const creditcards: CreditCard[] = state.creditcards.filter(
      (cc) => cc.id !== payload.id
    );

    context.setState({
      ...state,
      creditcards,
    });
  }

  @Action(CreditcardActions.Edit)
  editCreditcardByState(
    context: StateContext<CreditcardStateModel>,
    payload: CreditcardActions.Edit
  ) {}

  constructor(public translateService: TranslateService) {}
}
