import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class PageAPIService {
  constructor(private apiService: ApiService) {}

  getPage(
    page: string,
    locale: string,
    options?: { responseType: 'text' }
  ): Observable<any> {
    return this.apiService.get(`page/${page}?locale=${locale}`, options);
  }
}
