import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { ForgottenPasswordStep1Component } from '@app/components/forgotten-password/forgotten-password-step1/forgotten-password-step1.component';
import { ForgottenPasswordStep2Component } from '@app/components/forgotten-password/forgotten-password-step2/forgotten-password-step2.component';
import { DynamicForm } from '@app/models/Form';
import { AuthService } from '@app/store/auth/auth.service';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent implements OnInit {
  @Output() login = new EventEmitter();

  public loginForm: DynamicForm;

  constructor(
    private authService: AuthService,
    private translateService: TranslateService,
    private modalController: ModalController
  ) {}

  public ngOnInit(): void {
    this.loginForm = {
      fields: [
        {
          type: 'email',
          name: 'email',
          defaultValue: '',
          label: this.translateService.instant('SIGNIN.FORM.EMAIL.LABEL'),
          errorMessage: this.translateService.instant(
            'SIGNIN.FORM.EMAIL.ERRORS.REQUIRED'
          ),
          validators: [Validators.required, Validators.email],
          input: {
            type: 'email',
            placeholder: this.translateService.instant(
              'SIGNIN.FORM.EMAIL.PLACEHOLDER'
            ),
          },
        },
        {
          type: 'password',
          name: 'password',
          defaultValue: '',
          label: this.translateService.instant('SIGNIN.FORM.PASSWORD.LABEL'),
          errorMessage: this.translateService.instant(
            'SIGNIN.FORM.PASSWORD.ERRORS.REQUIRED'
          ),
          validators: [Validators.required],
          input: {
            type: 'text',
          },
        },
      ],
      buttons: [
        {
          type: 'submit',
          label: this.translateService.instant('SIGNIN.FORM.SUBMIT'),
        },
      ],
    };
  }

  async onSubmit(formData): Promise<any> {
    await this.authService.login(formData);
    this.login.emit(formData);
  }

  async openForgottenPasswordModal() {
    const step1 = await this.modalController.create({
      component: ForgottenPasswordStep1Component,
    });

    await step1.present();
    const { data: step1data } = await step1.onWillDismiss();

    if (!step1data?.email) {
      return;
    }

    const step2 = await this.modalController.create({
      component: ForgottenPasswordStep2Component,
      componentProps: {
        email: step1data.email,
      },
    });

    await step2.present();
    const { data: step2data } = await step2.onWillDismiss();

    if (!step2data?.email) {
      return;
    }

    this.onSubmit(step2data);
  }
}
