import { getActionTypeFromInstance } from '@ngxs/store';
import { AuthActions } from './auth/auth.actions';
import { defaultOrderState } from './order/order.state';
import { defaultPostcardState } from './postcard/postcard.state';
import { defaultRecipientState } from './recipient/recipient.state';
import { defaultSharedState } from './shared/shared.state';

export function logoutPlugin(state, action, next) {
  if (getActionTypeFromInstance(action) === AuthActions.Logout.type) {
    state = {
      recpients: {
        ...defaultRecipientState,
      },
      postcards: {
        ...defaultPostcardState,
      },
      auth: {},
      shared: { ...defaultSharedState, alreadyOpen: true },
      orders: { ...defaultOrderState },
    };
  }

  return next(state, action);
}
