<app-base-modal (closeAction)="dismissModal()" [title]="'SSO.ADDITIONAL_INFORMATIONS.TITLE' | translate">
  <div class="ion-padding" modalContent>
    <p>{{ 'SSO.ADDITIONAL_INFORMATIONS.CONTENT' | translate }}</p>
    <app-form *ngIf="this.askLegalInformationForm" [form]="askLegalInformationForm" (submit)="onSubmit($event)">
    </app-form>
  </div>
</app-base-modal>

<ng-template #cgvLabel>
  {{ 'SIGNUP.FORM.CGV.LABEL' | translate }}. <a>{{ 'CGV.SEE' | translate }}.</a>
</ng-template>