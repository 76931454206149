<div class="skForm__element" [formGroup]="group" [ngClass]="'skForm__element--' + field.type"
  (click)="onClick(field, $event)">
  <ng-container [ngSwitch]="field.type">
    <ng-container *ngSwitchCase="'checkbox'">
      <mat-checkbox color="primary" [formControlName]="field.name">
        <ng-container *ngIf="isString(field.label); else templateBlock"> {{ field.label }}</ng-container>
        <ng-template #templateBlock>
          <ng-container *ngTemplateOutlet="field.label"></ng-container>
        </ng-template>
      </mat-checkbox>
      <p @slideDown class="error" *ngIf="
        group.get(field.name).invalid &&
        group.get(field.name).touched &&
        field.errorMessage
      ">
        {{ field.errorMessage }}
      </p>
    </ng-container>

    <ng-container *ngSwitchCase="'group'">
      <app-form-group [group]="group.get(field.name)" [fields]="field.fields" [errorMessage]="field.errorMessage">
      </app-form-group>
    </ng-container>

    <ng-container *ngSwitchCase="'autocomplete'">
      <mat-form-field appearance="outline">
        <mat-label>{{ field.label }}</mat-label>
        <input *ngIf="field.type !== 'textarea'" [type]="field.input.type" matInput
          [placeholder]="field.input.placeholder || null" [formControlName]="field.name" [matAutocomplete]="auto" />
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option.label">
            {{ option.label }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="group.get(field.name).invalid && field.errorMessage">{{ field.errorMessage }}</mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container *ngSwitchCase="'content'">
      <ng-container *ngIf="isString(field.label); else templateBlock"> {{ field.label }}</ng-container>
      <ng-template #templateBlock>
        <ng-container *ngTemplateOutlet="field.label"></ng-container>
      </ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="'select'">
      <mat-form-field appearance="outline">
        <mat-label>{{ field.label }}<ng-container *ngIf="!isRequired"> ({{ 'GLOBAL.OPTIONNAL' | translate }})
          </ng-container>
        </mat-label>

        <ng-container *ngIf="isNativeSelect">
          <select matNativeControl [formControlName]="field.name">
            <option *ngFor="let option of options" [selected]="field.defaultValue === option.value"
              [value]="option.value">{{ option.label }}</option>
          </select>
        </ng-container>

        <ng-container *ngIf="!isNativeSelect">
          <mat-select matNativeControl [formControlName]="field.name">
            <mat-option *ngFor="let option of options" [value]="option.value">{{ option.label }}</mat-option>
          </mat-select>
        </ng-container>

        <mat-error *ngIf="group.get(field.name).invalid && field.errorMessage">{{ field.errorMessage }}</mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <mat-form-field appearance="outline">
        <mat-label>{{ field.label }}<ng-container *ngIf="!isRequired"> ({{ 'GLOBAL.OPTIONNAL' | translate }})
          </ng-container>
        </mat-label>
        <input [readonly]="field.readonly || null" *ngIf="field.type !== 'textarea'" [type]="field.input.type" matInput
          [placeholder]="field.input.placeholder || null" [attr.inputmode]="field.type === 'number' ? 'numeric' : null"
          [formControlName]="field.name" [maxlength]="field.input.maxlength || null"
          [pattern]="field.input.pattern || null" />
        <textarea *ngIf="field.type === 'textarea'" matInput [placeholder]="field.input.placeholder || null"
          [formControlName]="field.name"></textarea>
        <button *ngIf="field.type === 'password'" mat-icon-button type="button" matSuffix (click)="
          field.input.type === 'password'
            ? (field.input.type = 'text')
            : (field.input.type = 'password')
        " [attr.aria-label]="'Cacher la valeur'" [attr.aria-pressed]="field.input.type === 'password'">
          <ion-icon size="24" [name]="
            field.input.type === 'password'
              ? 'eye-outline'
              : 'eye-off-outline'
          "></ion-icon>
        </button>
        <mat-error *ngIf="group.get(field.name).invalid && field.errorMessage">{{ field.errorMessage }}</mat-error>
      </mat-form-field>
    </ng-container>
  </ng-container>
</div>