import { Component, OnInit, ViewChild } from '@angular/core';
import { DeviceService } from '@app/services/device.service';
import { LoadingService } from '@app/services/loading.service';
import { PageVisibilityService } from '@app/services/page-visibility.service';
import { StatusBarService } from '@app/services/statusbar.service';
import { SharedState } from '@app/store/shared/shared.state';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import {
  Capacitor,
  Plugins,
  registerWebPlugin,
  StatusBarStyle,
} from '@capacitor/core';
import {
  Config,
  IonRouterOutlet,
  NavController,
  Platform,
} from '@ionic/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { environment } from 'src/environments/environment';

const { SplashScreen } = Plugins;

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private store: Store,
    private translate: TranslateService,
    private pageVisibilityService: PageVisibilityService,
    private loadingService: LoadingService,
    private statusBarService: StatusBarService,
    public deviceService: DeviceService,
    private platform: Platform,
    private config: Config,
    private navCtrl: NavController
  ) {
    // @ts-ignore
    registerWebPlugin(FacebookLogin);
  }

  @ViewChild(IonRouterOutlet, { static: true })
  ionRouterOutlet: IonRouterOutlet;

  ngOnInit() {
    this.initializeApp();

    this.initializePushNotifications();

    this.pageVisibilityService.visibility
      .pipe(untilDestroyed(this))
      .subscribe((_) => {
        if (
          this.loadingService.isLoadingDisplayed &&
          !this.store.selectSnapshot(SharedState.loading)
        ) {
          this.loadingService.hide();
        }
      });

    this.platform.backButton.subscribeWithPriority(1, () => {
      if (this.ionRouterOutlet.canGoBack()) {
        this.navCtrl.pop();
      }
    });
  }

  initializePushNotifications() {
    this.deviceService.initialize();
  }

  initializeApp() {
    this.statusBarService.setStyle(StatusBarStyle.Light);
    this.statusBarService.setBackgroundColor({
      color: environment.statusBarColor,
    });
    this.statusBarService.setNavigationBarColor({
      color: environment.navigationBarColor,
    });

    if (Capacitor.isPluginAvailable('SplashScreen')) {
      SplashScreen.hide();
    }

    this.translate.addLangs(['en', 'fr']);
    this.translate.setDefaultLang('en');

    if (this.platform.is('ios')) {
      this.translate.onLangChange.subscribe((_) => {
        this.config.set(
          'backButtonText',
          this.translate.instant('ACTIONS.BACK')
        );
      });
    }
  }
}
