import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ModalComponent } from './modal.component';

@NgModule({
  declarations: [ModalComponent],
  exports: [ModalComponent],
  imports: [IonicModule],
})
export class ModalModule {}
