<ion-header translucent="true">
  <ion-toolbar>
    <ion-title>{{ title }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismissModal()">
        <ion-icon color="secondary" slot="icon-only" src="assets/icons/x.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ng-content select="[headerContent]"></ng-content>
</ion-header>

<ion-content>
  <ng-content select="[modalContent]"></ng-content>
</ion-content>