<div>
  <ion-button size="small" shape="round" color="white" (click)="facebookLogin()">
    <ion-icon src="assets/icons/facebook_logo.svg" slot="start"></ion-icon>
    Facebook
  </ion-button>
  <ion-button size="small" shape="round" color="white" (click)="googleSignIn()">
    <ion-icon src="assets/icons/google_logo.svg" slot="start"></ion-icon>
    Google
  </ion-button>
  <ion-button *ngIf="isIos" size="small" shape="round" color="white" (click)="appleSignIn()">
    <ion-icon src="assets/icons/apple_logo.svg" slot="start"></ion-icon>
    Apple
  </ion-button>
</div>