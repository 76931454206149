import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { SsoComponent } from './sso.component';

@NgModule({
  declarations: [SsoComponent],
  exports: [SsoComponent],
  imports: [IonicModule, CommonModule],
})
export class SsoModule {}
