import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/services/auth.guard';
import { GlobalGuard } from '@app/services/global.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('@app/pages/tabs/tabs.module').then((m) => m.TabsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'sign',
    loadChildren: () =>
      import('@app/pages/sign/sign.module').then((m) => m.SignPageModule),
    canActivate: [GlobalGuard],
  },
  {
    path: 'legal-notices',
    loadChildren: () =>
      import('@app/pages/legal-notices/legal-notices.module').then(
        (m) => m.LegalNoticesPageModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('@app/pages/landing/landing.module').then(
        (m) => m.LandingPageModule
      ),
    canActivate: [GlobalGuard],
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('@app/pages/contact/contact.module').then(
        (m) => m.ContactPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'credit-cards',
    loadChildren: () =>
      import('@app/pages/credit-cards/credit-cards.module').then(
        (m) => m.CreditCardsPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'password-change',
    loadChildren: () =>
      import('@app/pages/password-change/password-change.module').then(
        (m) => m.PasswordChangePageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'referral',
    loadChildren: () =>
      import('@app/pages/referral/referral.module').then(
        (m) => m.ReferralPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'language',
    loadChildren: () =>
      import('@app/pages/language/language.module').then(
        (m) => m.LanguagePageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'create-postcard/:format',
    loadChildren: () =>
      import('@app/pages/create-postcard/create-postcard.module').then(
        (m) => m.CreatePostcardPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'edit-postcard/:itemId',
    loadChildren: () =>
      import('@app/pages/create-postcard/create-postcard.module').then(
        (m) => m.CreatePostcardPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'credits',
    loadChildren: () =>
      import('./pages/credits/credits.module').then((m) => m.CreditsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./pages/about/about.module').then((m) => m.AboutPageModule),
  },
  {
    path: '**',
    loadChildren: () =>
      import('@app/pages/page-not-found/page-not-found.module').then(
        (m) => m.PageNotFoundPageModule
      ),
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
