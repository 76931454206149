import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { AsyncStorageEngine } from '@ngxs-labs/async-storage-plugin';
import { from, Observable } from 'rxjs';

const { Storage } = Plugins;

@Injectable({
  providedIn: 'root',
})
export class StorageService implements AsyncStorageEngine {
  constructor() {}

  length(): Observable<number> {
    return from(Storage.keys().then((data) => data.keys.length));
  }

  getItem(key: any): Observable<any> {
    return from(Storage.get({ key }).then((data) => JSON.parse(data.value)));
  }

  setItem(key: any, val: any): void {
    Storage.set({ key, value: JSON.stringify(val) });
  }

  removeItem(key: any): void {
    Storage.remove({ key });
  }

  clear(): void {
    Storage.clear();
  }

  key(val: number): Observable<string> {
    return from(Storage.keys().then((data) => data.keys[val]));
  }
}
