<ng-container *ngIf="formTag; else elseblock">
  <form (ngSubmit)="onSubmit($event)" class="skForm" *ngIf="buildedForm && inputForm">
    <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
  </form>
</ng-container>

<ng-template #formTemplate>
  <app-form-group [group]="buildedForm" [fields]="inputForm.fields">
  </app-form-group>

  <div class="skForm__element skForm__element--buttons ion-text-center" *ngIf="inputForm.buttons.length">
    <ion-button *ngFor="let button of inputForm.buttons" [type]="button.type" shape="round" color="secondary-gradient">
      <ng-container *ngIf="isString(button.label); else htmlBlock">
        {{ button.label }}
      </ng-container>

      <ng-template #htmlBlock>
        <ng-container *ngTemplateOutlet="button.label"></ng-container>
      </ng-template>
    </ion-button>
  </div>
</ng-template>

<ng-template #elseblock>
  <div class="skForm" *ngIf="buildedForm && inputForm">
    <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
  </div>
</ng-template>