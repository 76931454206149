import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Recipient } from '@app/models/Recipient';
import { RecipientActions } from './recipient.actions';
import { RecipientStateModel } from './recipient.model';

export const defaultRecipientState: RecipientStateModel = {
  recipients: [],
  touched: false,
};

@State<RecipientStateModel>({
  name: 'recipients',
  defaults: {
    ...defaultRecipientState,
  },
})
@Injectable()
export class RecipientState {
  @Selector()
  static recipients(state: RecipientStateModel): Recipient[] {
    return [...state.recipients].sort((a, b) => {
      if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) return -1;
      if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) return 1;
      return 0;
    });
  }

  @Selector()
  static touched(state: RecipientStateModel) {
    return state.touched;
  }

  @Action(RecipientActions.Create)
  createRecipientByState(
    context: StateContext<RecipientStateModel>,
    payload: RecipientActions.Create
  ) {}

  @Action(RecipientActions.FetchAll)
  fetchAllByState(
    context: StateContext<RecipientStateModel>,
    data: RecipientActions.FetchAll
  ) {
    const state = context.getState();
    const recipients: Recipient[] = data.payload;

    context.setState({
      ...state,
      recipients,
      touched: true,
    });
  }

  @Action(RecipientActions.FetchOne)
  fetchOneRecipientByState(
    context: StateContext<RecipientStateModel>,
    payload: RecipientActions.FetchOne
  ) {}

  @Action(RecipientActions.Delete)
  deleteRecipientByState(
    context: StateContext<RecipientStateModel>,
    payload: RecipientActions.Delete
  ) {
    const state = context.getState();
    const recipients: Recipient[] = state.recipients.filter(
      (r) => r.id !== payload.id
    );

    context.setState({
      ...state,
      recipients,
      touched: true,
    });
  }

  @Action(RecipientActions.Edit)
  editRecipientByState(
    context: StateContext<RecipientStateModel>,
    payload: RecipientActions.Edit
  ) {}

  constructor() {}
}
