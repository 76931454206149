export namespace AuthActions {
  export class Login {
    static readonly type = '[Auth] Login';
    constructor(public payload: any) {}
  }

  export class Register {
    static readonly type = '[Auth] Register';
    constructor(public payload: any) {}
  }

  export class Refresh {
    static readonly type = '[Auth] Refresh';
    constructor(public payload: any) {}
  }

  export class Logout {
    static readonly type = '[Auth] Logout';
  }

  export class UpdateUser {
    static readonly type = '[Auth] Update user';
    constructor(public payload: any) {}
  }
}
