export namespace RecipientActions {
  export class Create {
    static readonly type = '[Recipient] Create';
    constructor(public payload: any) {}
  }

  export class Edit {
    static readonly type = '[Recipient] Edit';
    constructor(public payload: any) {}
  }

  export class Delete {
    static readonly type = '[Recipient] Delete';
    constructor(public id: number) {}
  }

  export class FetchAll {
    static readonly type = '[Recipient] Fetch all';
    constructor(public payload: any) {}
  }

  export class FetchOne {
    static readonly type = '[Recipient] Fetch one';
    constructor(public id: number) {}
  }
}
