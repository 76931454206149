import { Injectable } from '@angular/core';
import { User } from '@app/models/User';
import { DeviceService } from '@app/services/device.service';
import { TranslateService } from '@ngx-translate/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import * as Sentry from '@sentry/angular';
import jwt_decode from 'jwt-decode';
import { AuthActions } from './auth.actions';
import { AuthStateModel } from './auth.model';
@State<AuthStateModel>({
  name: 'auth',
})
@Injectable()
export class AuthState implements NgxsOnInit {
  @Selector()
  static token(state: AuthStateModel): string | null {
    return state.token;
  }

  @Selector()
  static user(state: AuthStateModel): User | null {
    return state.user;
  }

  @Selector()
  static refreshToken(state: AuthStateModel): string | null {
    return state.refreshToken;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.token;
  }

  @Action(AuthActions.Login)
  loginByState(
    context: StateContext<AuthStateModel>,
    login: AuthActions.Login
  ) {
    const state = context.getState();
    const decodeToken = jwt_decode(login.payload.token) as any;
    const user = { ...decodeToken, ...login.payload?.data };

    context.setState({
      ...state,
      user,
      token: login.payload.token,
      refreshToken: login.payload.refresh_token,
    });

    this.updateSentryContext(user);

    this.deviceService.register();

    if (user?.locale) {
      this.translateService.use(user.locale);
    }
  }

  updateSentryContext(user) {
    Sentry.setContext('character', {
      ...user,
    });
  }

  @Action(AuthActions.UpdateUser)
  updateUserByState(
    context: StateContext<AuthStateModel>,
    update: AuthActions.UpdateUser
  ) {
    const state = context.getState();
    const user = update.payload.data ? update.payload.data : update.payload;
    context.setState({
      ...state,
      user,
    });

    this.updateSentryContext(user);
  }

  @Action(AuthActions.Logout)
  logoutByState(context: StateContext<AuthStateModel>) {
    this.deviceService.unregister();

    const state = context.getState();
    context.setState({
      ...state,
      user: null,
      token: null,
      refreshToken: null,
    });

    this.updateSentryContext({});
  }

  @Action(AuthActions.Refresh)
  refreshByState(
    context: StateContext<AuthStateModel>,
    refresh: AuthActions.Refresh
  ) {
    const state = context.getState();
    const decodeToken = jwt_decode(refresh.payload.token) as any;
    const user = { ...decodeToken, ...refresh.payload?.data };

    context.setState({
      ...state,
      user,
      token: refresh.payload.token,
      refreshToken: refresh.payload.refresh_token,
    });

    this.updateSentryContext(user);
  }

  constructor(
    private deviceService: DeviceService,
    private translateService: TranslateService
  ) {}

  ngxsOnInit(ctx: StateContext<AuthStateModel>): void {
    const state = ctx.getState();

    if (state.user) {
      this.updateSentryContext(state.user);
    }

    this.translateService.use(
      state?.user?.locale ||
        window.navigator.language.split('-')[0].toLowerCase()
    );
  }
}
