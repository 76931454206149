import { LoadingOptions } from '@app/models/LoadingOptions';

export namespace SharedActions {
  export class ShowLoading {
    static readonly type = '[Shared] ShowLoading';
    constructor(public options: LoadingOptions) {}
  }

  export class SetUploading {
    static readonly type = '[Shared] SetUploading';
    constructor(public uploading: boolean) {}
  }

  export class SetUploadingProgress {
    static readonly type = '[Shared] SetUploadingProgress';
    constructor(public progress: number) {}
  }

  export class HideLoading {
    static readonly type = '[Shared] HideLoading';
  }

  export class CloseStarterGuide {
    static readonly type = '[Shared] CloseStarterGuide';
  }

  export class CloseInfoCart {
    static readonly type = '[Shared] CloseInfoCart';
  }

  export class CGV {
    static readonly type = '[Shared] CGV';
    constructor(public content: string) {}
  }

  export class ThirdPartyLicenses {
    static readonly type = '[Shared] ThirdPartyLicenses';
    constructor(public content: string) {}
  }

  export class FetchCountries {
    static readonly type = '[Shared] Fetch all countries';
    constructor(public countries: any) {}
  }

  export class FetchCreditPacks {
    static readonly type = '[Shared] Fetch all creditPacks';
    constructor(public creditPacks: any) {}
  }
}
