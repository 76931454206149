import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class CountryAPIService {
  private oldLangCall = '';
  private oldCountries: any;
  private subject: Subject<any> = new Subject();

  constructor(private apiService: ApiService) {}

  getCountries(locale: string): Observable<any> {
    if (locale !== this.oldLangCall || !this.oldCountries) {
      this.oldLangCall = locale;

      this.apiService
        .get(`i18n/countries?locale=${locale}`)
        .pipe(take(1))
        .subscribe((data) => {
          this.oldCountries = data;
          this.subject.next(data);
        });
    }

    this.subject.next(this.oldCountries);

    return this.subject;
  }
}
