import { registerLocaleData } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouteReuseStrategy } from '@angular/router';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { AskForLegalInformationsModule } from '@app/components/ask-for-legal-informations/ask-for-legal-informations.module';
import { LoginExpiredModule } from '@app/components/login/login-expired/login-expired.module';
import { JwtInterceptorService } from '@app/services/jwt.interceptor';
import { StorageService } from '@app/services/storage.service';
import { AuthState } from '@app/store/auth/auth.state';
import { CreditcardState } from '@app/store/creditcard/creditcard.state';
import { logoutPlugin } from '@app/store/logout.plugin';
import { OrderState } from '@app/store/order/order.state';
import { PostcardState } from '@app/store/postcard/postcard.state';
import { RecipientState } from '@app/store/recipient/recipient.state';
import { SharedState } from '@app/store/shared/shared.state';
import { NgxTranslateModule } from '@app/translate.module';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsAsyncStoragePluginModule } from '@ngxs-labs/async-storage-plugin';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsModule, NGXS_PLUGINS } from '@ngxs/store';
import * as Sentry from '@sentry/angular';
import { environment } from 'src/environments/environment';
import { AppConstants, APP_CONST } from './app.config';

registerLocaleData(localeFr);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    NgxTranslateModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxsModule.forRoot(
      [
        SharedState,
        OrderState,
        AuthState,
        PostcardState,
        RecipientState,
        CreditcardState,
      ],
      {
        developmentMode: !environment.production,
      }
    ),
    NgxsDispatchPluginModule.forRoot(),
    NgxsAsyncStoragePluginModule.forRoot(StorageService),
    IonicStorageModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot(),
    HttpClientModule,
    LoginExpiredModule,
    AskForLegalInformationsModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: NGXS_PLUGINS,
      useValue: logoutPlugin,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true,
    },
    { provide: APP_CONST, useValue: AppConstants },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
