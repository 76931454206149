import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreditPack } from '@app/models/CreditPack';
import { LoadingOptions } from '@app/models/LoadingOptions';
import { UploadState } from '@app/models/Upload';
import { CountryAPIService } from '@app/services/country.api.service';
import { CreditPackAPIService } from '@app/services/creditpack.api.service';
import { PageAPIService } from '@app/services/page.api.service';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SharedActions } from './shared.actions';
import { SharedStateModel } from './shared.model';
import { SharedState } from './shared.state';

@Injectable({ providedIn: 'root' })
export class SharedService {
  @Select(SharedState)
  data$: Observable<SharedStateModel>;

  @Select(SharedState.loading)
  loading$: Observable<boolean>;

  @Select(SharedState.alreadyOpen)
  alreadyOpen$: Observable<boolean>;

  @Select(SharedState.alreadySeenCartInfo)
  alreadySeenCartInfo$: Observable<boolean>;

  @Select(SharedState.cgv)
  cgv$: Observable<string>;

  @Select(SharedState.thirdpartylicenses)
  thirdpartylicenses$: Observable<string>;

  @Select(SharedState.countries)
  countries$: Observable<any>;

  @Select(SharedState.creditPacks)
  creditPacks$: Observable<CreditPack[]>;

  @Select(SharedState.upload)
  upload$: Observable<UploadState>;

  constructor(
    private pageAPIService: PageAPIService,
    private countryAPIService: CountryAPIService,
    private creditPackAPIService: CreditPackAPIService,
    private httpClient: HttpClient
  ) {}

  @Dispatch()
  showLoading(options: LoadingOptions) {
    return new SharedActions.ShowLoading(options);
  }

  @Dispatch()
  setUploading(uploading: boolean) {
    return new SharedActions.SetUploading(uploading);
  }

  @Dispatch()
  setUploadingProgress(progress: number) {
    return new SharedActions.SetUploadingProgress(progress);
  }

  @Dispatch()
  getCGV(lang: string) {
    return this.pageAPIService
      .getPage('terms-of-usage', lang, { responseType: 'text' })
      .pipe(
        map((data) => new SharedActions.CGV(data)),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  @Dispatch()
  getThirdPartyLicenses() {
    return this.httpClient
      .get('3rdpartylicenses.txt', { responseType: 'text' })
      .pipe(
        map((data) => {
          return new SharedActions.ThirdPartyLicenses(data);
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  @Dispatch()
  getCountries(lang: string) {
    return this.countryAPIService.getCountries(lang).pipe(
      map((data) => new SharedActions.FetchCountries(data)),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  @Dispatch()
  getCreditPacks() {
    return this.creditPackAPIService.getCreditPacks().pipe(
      map((data) => new SharedActions.FetchCreditPacks(data)),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  @Dispatch()
  hideLoading() {
    return new SharedActions.HideLoading();
  }

  @Dispatch()
  closeStarterGuide() {
    return new SharedActions.CloseStarterGuide();
  }

  @Dispatch()
  closeInfoCart() {
    return new SharedActions.CloseInfoCart();
  }
}
