import { Injectable } from '@angular/core';
import { Credentials } from '@app/models/Credentials';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthAPIService {
  constructor(private apiService: ApiService) {}

  login(credentials: Credentials) {
    return this.apiService.post('user/login', credentials);
  }

  ssoRegister(data: any, provider: 'apple' | 'google' | 'facebook') {
    return this.apiService.post(`user/sso/${provider}`, data);
  }

  register(credentials: Credentials) {
    return this.apiService.post('user/register', credentials);
  }

  refresh(refreshToken: string) {
    return this.apiService.post('user/token/refresh', {
      refresh_token: refreshToken,
    });
  }
}
