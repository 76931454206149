import { Injectable } from '@angular/core';
import '@capacitor-community/firebase-analytics';
import { Plugins } from '@capacitor/core';
import { environment } from '@env/environment';

const { FirebaseAnalytics } = Plugins;

@Injectable({
  providedIn: 'root',
})
export class FirebaseAnalyticsService {
  constructor() {}

  logEvent(name: string, params: object = {}) {
    if (!environment.production) {
      return;
    }

    if (!name) {
      return;
    }

    FirebaseAnalytics.logEvent({
      name,
      params,
    });
  }
}
