<section>
    <p class="ion-text-center">{{ 'SIGNIN.METHODS.SSO' | translate }}</p>
    <app-sso (login)="login.emit()"></app-sso>
</section>

<div class="separator">{{ 'SIGN.OR' | translate }}</div>

<section>
    <p class="ion-text-center">{{ 'SIGNIN.METHODS.EMAIL' | translate }}</p>

    <app-form [form]="loginForm" (submit)="onSubmit($event)"></app-form>

    <div class="ion-text-center">
        <ion-button color="secondary" fill="clear" size="small" (click)="openForgottenPasswordModal()">
            {{ 'SIGNIN.FORGOTTEN_PASSWORD' | translate }}
        </ion-button>
    </div>
</section>