import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { AlertButton } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';

export interface ErrorPayload {
  title: string;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(
    public alertController: AlertController,
    private translateService: TranslateService
  ) {}

  private alert: HTMLIonAlertElement;

  async show(
    params: ErrorPayload = {
      title: this.translateService.instant('GLOBAL.ERROR.TITLE'),
      message: this.translateService.instant('GLOBAL.ERROR.MESSAGE'),
    },
    buttons: (string | AlertButton)[] = [
      this.translateService.instant('ACTIONS.OK'),
    ]
  ): Promise<HTMLIonAlertElement> {
    if (this.alert) {
      this.alert.dismiss();
    }

    const header = params.title;
    const message = params.message;

    this.alert = await this.alertController.create({
      translucent: true,
      header,
      message,
      buttons,
    });

    await this.alert.present();

    return this.alert;
  }

  async success(
    params: ErrorPayload = {
      title: this.translateService.instant('GLOBAL.ERROR.TITLE'),
      message: this.translateService.instant('GLOBAL.ERROR.MESSAGE'),
    },
    buttons: (string | AlertButton)[] = [
      this.translateService.instant('ACTIONS.OK'),
    ]
  ): Promise<HTMLIonAlertElement> {
    const header = params.title;
    const message = params.message;

    const alert = await this.alertController.create({
      cssClass: 'success-alert',
      translucent: true,
      header,
      message,
      buttons,
    });

    await alert.present();
    return alert;
  }

  async confirm(
    params: ErrorPayload = {
      title: this.translateService.instant('GLOBAL.CONFIRM.TITLE'),
      message: this.translateService.instant('GLOBAL.CONFIRM.MESSAGE'),
    }
  ) {
    const header = params.title;
    const message = params.message;

    const alert = await this.alertController.create({
      translucent: true,
      header,
      message,
      buttons: [
        {
          text: this.translateService.instant('ACTIONS.CANCEL'),
          role: 'cancel',
          handler: () => {
            alert.dismiss({ confirm: false });
            return false;
          },
        },
        {
          text: this.translateService.instant('ACTIONS.YES'),
          handler: () => {
            alert.dismiss({ confirm: true });
            return false;
          },
        },
      ],
    });

    await alert.present();

    return alert;
  }
}
