import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { DynamicForm } from '@app/models/Form';
import { AuthService } from '@app/store/auth/auth.service';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forgotten-password-step2',
  templateUrl: './forgotten-password-step2.component.html',
  styleUrls: ['./forgotten-password-step2.component.scss'],
})
export class ForgottenPasswordStep2Component implements OnInit {
  public forgottenPasswordStep2Form: DynamicForm;

  @Input() email: string;

  get i18nparam() {
    return {
      email: this.email,
    };
  }

  constructor(
    private translateService: TranslateService,
    private authService: AuthService,
    private modalController: ModalController,
    private alertController: AlertController
  ) {}

  ngOnInit() {
    this.forgottenPasswordStep2Form = {
      fields: [
        {
          type: 'number',
          name: 'code',
          defaultValue: '',
          label: this.translateService.instant(
            'FORGOTTEN_PASSWORD.STEP2.FORM.CODE.LABEL'
          ),
          errorMessage: this.translateService.instant(
            'FORGOTTEN_PASSWORD.STEP2.FORM.CODE.ERRORS.VALID'
          ),
          validators: [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(6),
            Validators.pattern('[0-9]{6}'),
          ],
          input: {
            type: 'text',
          },
        },
        {
          type: 'group',
          name: 'passwordgroup',
          errorMessage: this.translateService.instant(
            'FORGOTTEN_PASSWORD.STEP2.FORM.CONFIRM_PASSWORD.ERRORS.VALID'
          ),
          fields: [
            {
              type: 'password',
              name: 'password',
              defaultValue: '',
              label: this.translateService.instant(
                'FORGOTTEN_PASSWORD.STEP2.FORM.PASSWORD.LABEL'
              ),
              errorMessage: this.translateService.instant(
                'FORGOTTEN_PASSWORD.STEP2.FORM.PASSWORD.ERRORS.VALID'
              ),
              validators: [Validators.required, Validators.minLength(4)],
              input: {
                type: 'text',
              },
            },

            {
              type: 'password',
              name: 'password_confirm',
              defaultValue: '',
              label: this.translateService.instant(
                'FORGOTTEN_PASSWORD.STEP2.FORM.CONFIRM_PASSWORD.LABEL'
              ),
              errorMessage: this.translateService.instant(
                'FORGOTTEN_PASSWORD.STEP2.FORM.CONFIRM_PASSWORD.ERRORS.REQUIRED'
              ),
              validators: [Validators.required, Validators.minLength(4)],
              input: {
                type: 'text',
                placeholder: this.translateService.instant(
                  'SIGNUP.FORM.CONFIRM_PASSWORD.PLACEHOLDER'
                ),
              },
            },
          ],
          validators: [
            function passwordMatchValidator(g: FormGroup) {
              return g.get('password').valid &&
                g.get('password_confirm').valid &&
                g.get('password').value === g.get('password_confirm').value
                ? null
                : { mismatch: true };
            },
          ],
        },
      ],
      buttons: [
        {
          type: 'submit',
          label: this.translateService.instant(
            'FORGOTTEN_PASSWORD.STEP2.FORM.SUBMIT'
          ),
        },
      ],
    };
  }

  async onSubmit(formData) {
    if (!formData.code) {
      return;
    }

    await this.authService.resetPasswordCode({
      code: formData.code,
      email: this.email,
    });

    await this.authService.updateUser({
      password: formData.passwordgroup.password,
    });

    const alert = await this.alertController.create({
      backdropDismiss: false,
      header: this.translateService.instant(
        'FORGOTTEN_PASSWORD.STEP2.SUCCESS.TITLE'
      ),
      message: this.translateService.instant(
        'FORGOTTEN_PASSWORD.STEP2.SUCCESS.MESSAGE'
      ),
      buttons: [
        {
          text: this.translateService.instant('ACTIONS.OK'),
          handler: () => {
            this.dismissModal({
              email: this.email,
              password: formData.passwordgroup.password,
            });
          },
        },
      ],
    });

    await alert.present();
  }

  dismissModal(data = null) {
    this.modalController.dismiss(data);
  }
}
