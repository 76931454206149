import { Injectable } from '@angular/core';
import { CreatePostcard, Postcard, PostcardStatus } from '@app/models/Postcard';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { PostcardActions } from './postcard.actions';
import { PostcardStateModel } from './postcard.model';

export const defaultPostcardState: PostcardStateModel = {
  postcards: [],
  createPostcard: {
    format: null,
    nbPhotos: null,
    pictures: [],
    content: '',
    postcardName: '',
    postcardId: null,
    recipients: [],
  },
};

@State<PostcardStateModel>({
  name: 'postcards',
  defaults: {
    ...defaultPostcardState,
  },
})
@Injectable()
export class PostcardState {
  @Selector()
  static postcards(state: PostcardStateModel): Postcard[] {
    return state.postcards
      .filter((el: Postcard) => el.status === PostcardStatus.Draft)
      .reverse();
  }

  @Selector()
  static createPostcard(state: PostcardStateModel): CreatePostcard {
    return state.createPostcard;
  }

  @Action(PostcardActions.Create)
  createPostcardByState(
    context: StateContext<PostcardStateModel>,
    payload: PostcardActions.Create
  ) {}

  @Action(PostcardActions.FetchAll)
  fetchAllByState(
    context: StateContext<PostcardStateModel>,
    data: PostcardActions.FetchAll
  ) {
    const state = context.getState();
    const postcards: Postcard[] = data.payload;

    context.setState({
      ...state,
      postcards,
    });
  }

  @Action(PostcardActions.FetchOne)
  fetchOnePostcardByState(
    context: StateContext<PostcardStateModel>,
    payload: PostcardActions.FetchOne
  ) {}

  @Action(PostcardActions.Delete)
  deletePostcardByState(
    context: StateContext<PostcardStateModel>,
    payload: PostcardActions.Delete
  ) {}

  @Action(PostcardActions.Edit)
  editPostcardByState(
    context: StateContext<PostcardStateModel>,
    payload: PostcardActions.Edit
  ) {}

  @Action(PostcardActions.AddPhotosToCreate)
  addPhotosToCreateByState(
    context: StateContext<PostcardStateModel>,
    { photos }: PostcardActions.AddPhotosToCreate
  ) {
    const state = context.getState();
    const createPostcard = {
      ...state.createPostcard,
      pictures: [...photos],
    };

    context.patchState({
      createPostcard,
    });
  }

  @Action(PostcardActions.InitCreatePostcard)
  initCreateByState(
    context: StateContext<PostcardStateModel>,
    { format, nbPhotos }: PostcardActions.InitCreatePostcard
  ) {
    const state = context.getState();
    const createPostcard = {
      ...state.createPostcard,
      format,
      nbPhotos,
    };

    context.patchState({
      createPostcard,
    });
  }

  @Action(PostcardActions.AddContentToCreate)
  addContentToCreateByState(
    context: StateContext<PostcardStateModel>,
    { content }: PostcardActions.AddContentToCreate
  ) {
    const state = context.getState();
    const createPostcard = {
      ...state.createPostcard,
      content,
    };

    context.patchState({
      createPostcard,
    });
  }

  @Action(PostcardActions.AddNameToCreate)
  addNameToCreateByState(
    context: StateContext<PostcardStateModel>,
    { postcardName }: PostcardActions.AddNameToCreate
  ) {
    const state = context.getState();
    const createPostcard = {
      ...state.createPostcard,
      postcardName,
    };

    context.patchState({
      createPostcard,
    });
  }

  @Action(PostcardActions.AddRecipientsToCreate)
  addRecipientsToCreateByState(
    context: StateContext<PostcardStateModel>,
    { recipients }: PostcardActions.AddRecipientsToCreate
  ) {
    const state = context.getState();
    console.log(state.createPostcard);
    const createPostcard = {
      ...state.createPostcard,
      recipients: [...(recipients || [])],
    };
    console.log(createPostcard);

    context.patchState({
      createPostcard,
    });
  }

  @Action(PostcardActions.AddPostcardidToCreate)
  addPostcardidToCreateByState(
    context: StateContext<PostcardStateModel>,
    { postcardId }: PostcardActions.AddPostcardidToCreate
  ) {
    const state = context.getState();
    const createPostcard = {
      ...state.createPostcard,
      postcardId,
    };

    context.patchState({
      createPostcard,
    });
  }

  @Action(PostcardActions.SetCreatePostcard)
  setCreatePostcardByState(
    context: StateContext<PostcardStateModel>,
    { createPostcard }: PostcardActions.SetCreatePostcard
  ) {
    const newCreatePostcard = {
      ...defaultPostcardState.createPostcard,
      ...createPostcard,
    };

    context.patchState({
      createPostcard: newCreatePostcard,
    });
  }

  @Action(PostcardActions.ClearCreatePostcard)
  clearCreateByState(context: StateContext<PostcardStateModel>) {
    const createPostcard = {
      ...defaultPostcardState.createPostcard,
    };

    context.patchState({
      createPostcard,
    });
  }

  constructor() {}
}
