import { Injectable } from '@angular/core';
import {
  Capacitor,
  Plugins,
  StatusBarBackgroundColorOptions,
  StatusBarStyle,
} from '@capacitor/core';
const { StatusBar } = Plugins;

@Injectable({
  providedIn: 'root',
})
export class StatusBarService {
  setBackgroundColor(options: StatusBarBackgroundColorOptions) {
    if (Capacitor.isPluginAvailable('StatusBar')) {
      StatusBar.setBackgroundColor(options);
      // comment for now... https://github.com/ionic-team/capacitor/issues/3088
      // StatusBar.setOverlaysWebView({
      //   overlay: true,
      // });
    }
  }

  setStyle(style: StatusBarStyle) {
    if (Capacitor.isPluginAvailable('StatusBar')) {
      StatusBar.setStyle({
        style,
      });
    }
  }

  setNavigationBarColor(options) {
    // if (Capacitor.isPluginAvailable('NavigationBar')) {
    //   NavigationBar.setBackgroundColor(options);
    // }
  }
}
