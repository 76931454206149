import { Injectable } from '@angular/core';
import { Order, OrderStatus } from '@app/models/Order';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { OrderActions } from './order.actions';
import { OrderStateModel } from './order.model';

export const defaultOrderState: OrderStateModel = {
  orders: [],
  currentOrder: null,
};

@State<OrderStateModel>({
  name: 'orders',
  defaults: {
    ...defaultOrderState,
  },
})
@Injectable()
export class OrderState {
  @Selector()
  static orders(state: OrderStateModel): Order[] {
    return state.orders
      .filter((el) => el.status === OrderStatus.Paid)
      .reverse();
  }

  @Selector()
  static currentOrder(state: OrderStateModel): Order {
    return state.currentOrder;
  }

  @Selector()
  static currentOrderSumCredits(state: OrderStateModel): number {
    return state.currentOrder.items.reduce((acc, val) => {
      acc += val.recipients.length;

      return acc;
    }, 0);
  }

  @Selector()
  static drafts(state: OrderStateModel): Order[] {
    return state.orders
      .filter((el) => el.status === OrderStatus.Draft)
      .reverse();
  }

  @Action(OrderActions.Create)
  createOrderByState(
    context: StateContext<OrderStateModel>,
    data: OrderActions.Create
  ) {
    const state = context.getState();
    const order = data.payload;

    context.setState({
      ...state,
      currentOrder: order,
    });
  }

  @Action(OrderActions.AddItemTo)
  addItemToOrderByState(
    context: StateContext<OrderStateModel>,
    payload: OrderActions.AddItemTo
  ) {
    console.log(payload);
  }

  @Action(OrderActions.FetchAll)
  fetchAllByState(
    context: StateContext<OrderStateModel>,
    data: OrderActions.FetchAll
  ) {
    const state = context.getState();
    const orders: Order[] = data.payload;

    context.setState({
      ...state,
      orders,
    });
  }

  @Action(OrderActions.FetchOne)
  fetchOneOrderByState(
    context: StateContext<OrderStateModel>,
    payload: OrderActions.FetchOne
  ) {}

  @Action(OrderActions.Delete)
  deleteOrderByState(
    context: StateContext<OrderStateModel>,
    payload: OrderActions.Delete
  ) {
    const state = context.getState();
    const currentOrder: Order = state.currentOrder;
    const afterRemoveArray = currentOrder.items.filter(
      (el) => el.id !== payload.id
    );

    context.setState({
      ...state,
      currentOrder: {
        ...currentOrder,
        items: afterRemoveArray,
      },
    });
  }

  @Action(OrderActions.Edit)
  editOrderByState(
    context: StateContext<OrderStateModel>,
    payload: OrderActions.Edit
  ) {}

  constructor() {}
}
