import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { SlideDownAnimation } from '@app/components/form/slidedown.animation';
import { DynamicField, DynamicFieldOption } from '@app/models/Form';
import { Platform } from '@ionic/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isObservable, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-form-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
  animations: [SlideDownAnimation],
})
export class FormFieldComponent implements OnInit {
  @HostBinding('class')
  get className() {
    return this.field.name;
  }

  @Input() group: FormGroup;
  @Input() field: DynamicField;

  isNativeSelect = false;

  options: DynamicFieldOption[];
  filteredOptions: Observable<DynamicFieldOption[]>;

  isString(data) {
    return typeof data === 'string';
  }

  isObservable(testElement) {
    return isObservable(testElement);
  }

  get isRequired() {
    const fieldValidator = this.group.get(this.field.name).validator;

    return fieldValidator && fieldValidator.length
      ? fieldValidator({} as AbstractControl)?.required
      : false;
  }

  constructor(private platform: Platform) {}

  ngOnInit() {
    if (this.field.options && this.isObservable(this.field.options)) {
      (this.field.options as Observable<DynamicFieldOption[]>)
        .pipe(untilDestroyed(this))
        .subscribe((data) => (this.options = data));
    } else if (this.field.options && !this.isObservable(this.field.options)) {
      this.options = [...(this.field.options as DynamicFieldOption[])];
    }

    if (this.platform.is('android') && this.platform.is('hybrid')) {
      this.isNativeSelect = true;
    }

    if (this.field.type === 'autocomplete' && this.field.options) {
      this.options = [...(this.field.options as DynamicFieldOption[])];

      this.filteredOptions = this.group.get(this.field.name).valueChanges.pipe(
        untilDestroyed(this),
        startWith(''),
        map((value) => this.filter(value))
      );
    }
  }

  onClick(field: DynamicField, $event: Event) {
    if (!field.click) {
      return;
    }

    field.click($event, field);
  }

  private filter(value: string): DynamicFieldOption[] {
    const filterValue = value.toLowerCase();

    const result = this.options.filter((option) => {
      return option.label.toLowerCase().includes(filterValue);
    });

    return result;
  }
}
