import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormFieldComponent } from './field/field.component';
import { FormComponent } from './form.component';
import { FormGroupComponent } from './group/group.component';

@NgModule({
  declarations: [FormComponent, FormFieldComponent, FormGroupComponent],
  exports: [FormComponent, FormFieldComponent, FormGroupComponent],
  imports: [
    MaterialModule,
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
  ],
})
export class SkypaperFormModule {}
