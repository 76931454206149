import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginExpiredComponent } from '@app/components/login/login-expired/login-expired.component';
import { AuthService } from '@app/store/auth/auth.service';
import { AuthState } from '@app/store/auth/auth.state';
import { SharedService } from '@app/store/shared/shared.service';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class JwtInterceptorService implements HttpInterceptor {
  private isRefreshingToken = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private store: Store,
    private authService: AuthService,
    private sharedService: SharedService,
    private router: Router,
    public modalController: ModalController
  ) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.store.selectSnapshot(AuthState.token);

    if (token) {
      const clone = this.addTokenToRequest(token, req);

      return next.handle(clone).pipe(
        catchError((err) => {
          return this.handleHttpErrors(err, req, next);
        })
      );
    }

    return next.handle(req);
  }

  addTokenToRequest(token: string, req: HttpRequest<any>) {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  handleHttpErrors(error: any, request: HttpRequest<any>, next: HttpHandler) {
    if (error.status === 401) {
      return this.handle401errors(request, next);
    }

    return throwError(new Error(error));
  }

  handle401errors(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.refreshTokenSubject.next(null);

      const refreshToken = this.store.selectSnapshot(AuthState.refreshToken);

      this.authService.refresh(refreshToken).then(
        (data) => {
          if (!data?.payload?.token) {
            return this.promptRelogin(next, request);
          }
          return this.replayrequest(next, data.payload.token, request);
        },
        (err) => {
          return this.promptRelogin(next, request, err);
        }
      );
    }

    if (request.url.includes('user/token/refresh')) {
      // we met a 401, but refresh also fire a 401
      this.promptRelogin(next, request);
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token != null),
      take(1),
      switchMap((jwt) => {
        return next.handle(this.addTokenToRequest(jwt, request));
      })
    );
  }

  replayrequest(next, token, request) {
    this.isRefreshingToken = false;
    this.refreshTokenSubject.next(token);

    const clone = this.addTokenToRequest(token, request);

    return next.handle(clone).pipe(
      catchError((err) => {
        this.handleHttpErrors(err, request, next);
        return throwError(new Error(err));
      })
    );
  }

  async promptRelogin(next, request, err?) {
    this.sharedService.hideLoading();

    const modal = await this.modalController.create({
      component: LoginExpiredComponent,
    });

    await modal.present();

    modal.onDidDismiss().then(({ data }) => {
      if (data && data.token && !request.url.includes('user/token/refresh')) {
        return this.replayrequest(next, data.token, request);
      }

      this.authService.logout();
      this.router.navigate(['/']);
      return err ? throwError(new Error(err)) : '';
    });
  }
}
