import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CreditPackAPIService {
  constructor(private apiService: ApiService) {}

  getCreditPacks(): Observable<any> {
    return this.apiService.get('creditPacks');
  }

  getOneCreditPack(creditPackId: number) {
    return this.apiService.get(`creditPack/${creditPackId}`);
  }
}
