import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { SsoService } from '@app/services/sso.service';
import { AuthService } from '@app/store/auth/auth.service';
import { Plugins } from '@capacitor/core';

@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsoComponent implements OnInit {
  isIos = false;

  @Output() login = new EventEmitter();

  constructor(
    private ssoService: SsoService,
    private authService: AuthService,
    private cd: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    const { Device } = Plugins;
    const device = await Device.getInfo();
    this.isIos = device.platform === 'ios';
    this.cd.markForCheck();
  }

  async appleSignIn() {
    const loginData = await this.ssoService.appleLogin();

    if (!loginData) {
      return;
    }

    await this.authService.ssoLogin(loginData, 'apple');

    this.login.emit();
  }

  async googleSignIn() {
    const loginData = await this.ssoService.googleLogin();

    if (!loginData) {
      return;
    }

    await this.authService.ssoLogin(loginData, 'google');

    this.login.emit();
  }

  async facebookLogin() {
    const loginData = await this.ssoService.facebookLogin();

    if (!loginData) {
      return;
    }

    await this.authService.ssoLogin(loginData, 'facebook');

    this.login.emit();
  }
}
