import { Recipient } from './Recipient';

export interface Postcard {
  id: number;
  name: string;
  content: string;
  status: PostcardStatus;
  borderEnabled: false;
  readonly created?: number;
  readonly updated?: number;
  path: PostcardPath;
  format?: string;
}

export enum PostcardStatus {
  Draft = 0,
  Used = 5,
  Disabled = 10,
}
interface PostcardPath {
  original: string;
}

export interface CreatePostcard {
  format: string;
  pictures: string[];
  content: string;
  recipients: Recipient[];
  postcardId: number;
  postcardName: string;
  nbPhotos?: number;
}
