import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-base-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @HostBinding('class') class = 'ion-page';

  @Input() title: string;
  @Output() closeAction: EventEmitter<any> = new EventEmitter();

  constructor(public modalController: ModalController) {}

  ngOnInit() {}

  dismissModal() {
    this.closeAction.emit();
  }
}
