import { CreditPack } from '@app/models/CreditPack';
import { UploadState } from '@app/models/Upload';

export class SharedStateModel {
  loading: boolean;
  alreadyOpen: boolean;
  alreadySeenCartInfo: boolean;
  cgv: string;
  thirdpartylicenses: string;
  countries: any;
  creditPacks: CreditPack[];
  upload: UploadState;
}
