import { Injectable } from '@angular/core';
import {
  Capacitor,
  Plugins,
  PushNotification,
  PushNotificationActionPerformed,
  PushNotificationToken,
} from '@capacitor/core';
import { ApiService } from '@app/services/api.service';

const { PushNotifications } = Plugins;

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  private deviceToken: string;
  private operatingSystem: string;

  constructor(private apiService: ApiService) {
    this.operatingSystem = Capacitor.getPlatform();
  }

  initialize() {
    if (Capacitor.isPluginAvailable('PushNotifications')) {
      PushNotifications.requestPermission().then((result) => {
        if (result.granted) {
          PushNotifications.register();
        } else {
        }
      });

      PushNotifications.addListener(
        'registration',
        (token: PushNotificationToken) => {
          this.deviceToken = token.value;
        }
      );

      PushNotifications.addListener('registrationError', (error: any) => {
        console.log(error);
      });

      PushNotifications.addListener(
        'pushNotificationReceived',
        (notification: PushNotification) => {
          console.log(notification);
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (notification: PushNotificationActionPerformed) => {
          console.log(notification);
        }
      );
    }
  }

  register() {
    if (this.operatingSystem === 'web') {
      return;
    }

    return this.apiService
      .post('user/device', {
        identifier: this.deviceToken,
        system: this.operatingSystem,
      })
      .subscribe();
  }

  unregister() {
    if (this.operatingSystem === 'web') {
      return;
    }

    const encodedParams = encodeURI(
      '?identifier=' + this.deviceToken + '&system=' + this.operatingSystem
    );
    return this.apiService.delete('user/device' + encodedParams).subscribe();
  }
}
