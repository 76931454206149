import { Injectable } from '@angular/core';
import { fromEvent, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PageVisibilityService {
  visibility = new ReplaySubject<any>(1);

  constructor() {
    fromEvent(document, 'visibilitychange')
      .pipe(
        map(evt => {
          return evt;
        })
      )
      .subscribe(data => {
        this.visibility.next((data.target as Document).visibilityState);
      });
  }
}
