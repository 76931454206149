import { NgModule } from '@angular/core';
import { ForgottenPasswordStep1Module } from '@app/components/forgotten-password/forgotten-password-step1/forgotten-password-step1.module';
import { ForgottenPasswordStep2Module } from '@app/components/forgotten-password/forgotten-password-step2/forgotten-password-step2.module';
import { SkypaperFormModule } from '@app/components/form/form.module';
import { SsoModule } from '@app/components/sso/sso.module';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { LoginFormComponent } from './login-form.component';

@NgModule({
  declarations: [LoginFormComponent],
  exports: [LoginFormComponent],
  imports: [
    TranslateModule,
    SkypaperFormModule,
    SsoModule,
    IonicModule,
    ForgottenPasswordStep2Module,
    ForgottenPasswordStep1Module,
  ],
})
export class LoginFormModule {}
