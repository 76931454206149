import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { combineLatest, EMPTY, Observable, throwError, timer } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private readonly MIN_RESPONSE_TIME = 200;

  constructor(public http: HttpClient) {}

  request(req: Observable<any>, delay: number): Observable<any> {
    return combineLatest([timer(delay), req]).pipe(
      map((x) => x[1]),
      catchError((error) => this.handleError(error))
    );
  }

  get(
    url: string,
    options: any = {},
    delay: number = this.MIN_RESPONSE_TIME
  ): Observable<any> {
    return this.request(
      this.http.get(`${environment.apiUrl}${url}`, options),
      delay
    );
  }

  delete(
    url: string,
    options: any = {},
    delay: number = this.MIN_RESPONSE_TIME
  ): Observable<any> {
    return this.request(
      this.http.delete(`${environment.apiUrl}${url}`, options),
      delay
    );
  }

  post(
    url: string,
    data: any = {},
    options: any = {},
    delay: number = this.MIN_RESPONSE_TIME
  ): Observable<any> {
    return this.request(
      this.http.post(`${environment.apiUrl}${url}`, data, options),
      delay
    );
  }

  put(
    url: string,
    data: any = {},
    options: any = {},
    delay: number = this.MIN_RESPONSE_TIME
  ): Observable<any> {
    return this.request(
      this.http.put(`${environment.apiUrl}${url}`, data, options),
      delay
    );
  }

  patch(
    url: string,
    options: any = {},
    data: any = {},
    delay: number = this.MIN_RESPONSE_TIME
  ): Observable<any> {
    return this.request(
      this.http.patch(`${environment.apiUrl}${url}`, data, options),
      delay
    );
  }

  upload(url: string, data: any = {}): Observable<HttpEvent<any>> {
    const req = new HttpRequest('POST', `${environment.apiUrl}${url}`, data, {
      reportProgress: true,
    });

    return this.http.request(req);
  }

  private handleError(err: HttpErrorResponse) {
    if (!err) {
      return EMPTY;
    }

    console.log(err);

    if (err && err.status !== 401) {
      Sentry.captureException(err);
    }

    return throwError(err);
  }
}
