import { Injectable } from '@angular/core';
import { AlertService } from '@app/services/alert.service';
import { AuthAPIService as AuthenticationService } from '@app/services/auth.api.service';
import { UserAPIService } from '@app/services/user.api.service';
import { SharedService } from '@app/store/shared/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select, Store } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { AuthActions } from './auth.actions';
import { AuthStateModel } from './auth.model';
import { AuthState } from './auth.state';

@Injectable({ providedIn: 'root' })
export class AuthService {
  @Select(AuthState)
  data$: Observable<AuthStateModel>;

  constructor(
    private authService: AuthenticationService,
    private errorService: AlertService,
    private sharedService: SharedService,
    private userAPIService: UserAPIService,
    private translateService: TranslateService,
    private store: Store
  ) {}

  @Dispatch()
  async login(payload) {
    this.sharedService.showLoading({
      text: this.translateService.instant('SIGNIN.CONNECTING'),
    });

    return await this.authService
      .login(payload)
      .pipe(
        map((data) => new AuthActions.Login(data)),
        finalize(() => this.sharedService.hideLoading()),
        catchError((error) => {
          console.log(error);
          this.errorService.show({
            title: this.translateService.instant(
              'SIGNIN.ERRORS.WRONG_CREDENTIALS.TITLE'
            ),
            message: this.translateService.instant(
              'SIGNIN.ERRORS.WRONG_CREDENTIALS.MESSAGE'
            ),
          });
          return throwError(error);
        })
      )
      .toPromise();
  }

  async updatePassword(oldPassword, newPassword) {
    this.sharedService.showLoading({
      text: this.translateService.instant('PASSWORD_CHANGE.UPDATING'),
    });

    const user = this.store.selectSnapshot(AuthState.user);

    await this.authService
      .login({
        email: user.email,
        password: oldPassword,
      })
      .pipe(
        catchError((error) => {
          console.log(error);
          this.sharedService.hideLoading();
          this.errorService.show({
            title: this.translateService.instant(
              'PASSWORD_CHANGE.ERRORS.VERIFY_PASSWORD.TITLE'
            ),
            message: this.translateService.instant(
              'PASSWORD_CHANGE.ERRORS.VERIFY_PASSWORD.MESSAGE'
            ),
          });
          return throwError(error);
        })
      )
      .toPromise();

    const resultUpdate = await this.updateUser({
      password: newPassword,
    });

    if (!resultUpdate.payload) {
      this.sharedService.hideLoading();
      this.errorService.show({
        title: this.translateService.instant(
          'PASSWORD_CHANGE.ERRORS.CHANGE_PASSWORD.TITLE'
        ),
        message: this.translateService.instant(
          'PASSWORD_CHANGE.ERRORS.CHANGE_PASSWORD.MESSAGE'
        ),
      });

      return;
    }

    await this.getUser();

    this.sharedService.hideLoading();
  }

  @Dispatch()
  async ssoLogin(payload, provider) {
    this.sharedService.showLoading({
      text: this.translateService.instant('SIGNIN.CONNECTING'),
    });

    return await this.authService
      .ssoRegister(payload, provider)
      .pipe(
        map((data) => new AuthActions.Login(data)),
        finalize(() => this.sharedService.hideLoading()),
        catchError((error) => {
          console.log(error);
          this.errorService.show({
            title: this.translateService.instant('SSO.ERRORS.CONNECTING.TITLE'),
            message: this.translateService.instant(
              'SSO.ERRORS.CONNECTING.MESSAGE'
            ),
          });
          return throwError(error);
        })
      )
      .toPromise();
  }

  @Dispatch()
  async resetPasswordCode(payload) {
    this.sharedService.showLoading({
      text: this.translateService.instant(
        'FORGOTTEN_PASSWORD.STEP2.CODE_VALIDATING'
      ),
    });

    return await this.userAPIService
      .checkResetPasswordCode(payload)
      .pipe(
        map((data) => new AuthActions.Login(data)),
        finalize(() => this.sharedService.hideLoading()),
        catchError((error) => {
          console.log(error);
          this.errorService.show({
            title: this.translateService.instant(
              'FORGOTTEN_PASSWORD.STEP2.ERRORS.CODE_INCORRECT.TITLE'
            ),
            message: this.translateService.instant(
              'FORGOTTEN_PASSWORD.STEP2.ERRORS.CODE_INCORRECT.MESSAGE'
            ),
          });
          return throwError(error);
        })
      )
      .toPromise();
  }

  @Dispatch()
  async updateUser(
    payload,
    i18n = {
      loading: this.translateService.instant(
        'FORGOTTEN_PASSWORD.STEP2.PASSWORD_CHANGING'
      ),
      errorTitle: this.translateService.instant(
        'FORGOTTEN_PASSWORD.STEP2.ERRORS.MAJ.TITLE'
      ),
      errorMessage: this.translateService.instant(
        'FORGOTTEN_PASSWORD.STEP2.ERRORS.MAJ.MESSAGE'
      ),
    }
  ) {
    this.sharedService.showLoading({
      text: i18n.loading,
    });

    return await this.userAPIService
      .updateUser(payload)
      .pipe(
        map((data) => new AuthActions.UpdateUser(data)),
        finalize(() => this.sharedService.hideLoading()),
        catchError((error) => {
          console.log(error);
          this.errorService.show({
            title: i18n.errorTitle,
            message: i18n.errorMessage,
          });
          return throwError(error);
        })
      )
      .toPromise();
  }

  @Dispatch()
  async register(payload) {
    this.sharedService.showLoading({
      text: this.translateService.instant('SIGNUP.REGISTERING'),
    });

    return await this.authService
      .register(payload)
      .pipe(
        map((data) => new AuthActions.Login(data)),
        finalize(() => this.sharedService.hideLoading()),
        catchError((error) => {
          if (error.status === 409) {
            this.errorService.show({
              title: this.translateService.instant(
                'SIGNUP.ERROR.USER_EXISTS.TITLE'
              ),
              message: this.translateService.instant(
                'SIGNUP.ERROR.USER_EXISTS.MESSAGE'
              ),
            });
          } else {
            this.errorService.show();
          }

          return throwError(error);
        })
      )
      .toPromise();
  }

  @Dispatch()
  logout() {
    return new AuthActions.Logout();
  }

  @Dispatch()
  async refresh(token: string) {
    return await this.authService
      .refresh(token)
      .pipe(
        map((data) => new AuthActions.Refresh(data)),
        catchError((error) => {
          this.sharedService.hideLoading();
          this.errorService.show();
          return throwError(error);
        })
      )
      .toPromise();
  }

  @Dispatch()
  async getUser(silent = true) {
    if (!silent) {
      this.sharedService.showLoading({
        text: this.translateService.instant('SIGNUP.REGISTERING'),
      });
    }

    return await this.userAPIService
      .getUser()
      .pipe(
        map((data) => new AuthActions.UpdateUser(data)),
        finalize(() => (!silent ? this.sharedService.hideLoading() : null)),
        catchError((error) => {
          console.log(error);
          if (!silent) {
            this.errorService.show();
          }
          return throwError(error);
        })
      )
      .toPromise();
  }
}
