import { NgModule } from '@angular/core';
import { LoginFormModule } from '@app/components/login/login-form/login-form.module';
import { ModalModule } from '@app/components/modal/modal.module';
import { LoginExpiredComponent } from './login-expired.component';

@NgModule({
  declarations: [LoginExpiredComponent],
  exports: [LoginExpiredComponent],
  imports: [LoginFormModule, ModalModule],
})
export class LoginExpiredModule {}
