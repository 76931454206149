let isDarkTheme = false;

const toggleDarkTheme = (shouldAdd) => {
  document.body.classList.toggle('dark-mode', false);
  isDarkTheme = false;
};

const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

toggleDarkTheme(prefersDark.matches);

// Listen for changes to the prefers-color-scheme media query
prefersDark.addListener((mediaQuery) => {
  toggleDarkTheme(mediaQuery.matches);
});

export { toggleDarkTheme, isDarkTheme };
