import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from '@app/store/auth/auth.state';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private store: Store, private router: Router) {}

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isAuthenticated = this.store.selectSnapshot(
      AuthState.isAuthenticated
    );

    if (isAuthenticated) {
      return true;
    }

    this.router.navigate(['/sign'], {
      queryParams: {
        return: state.url,
      },
    });
  }
}
